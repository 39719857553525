<template>
  <register-layout v-if="!hasToken">
    <form class="login-form" novalidate @submit.prevent="performLogin">
      <LoadingZone
        :loading="loadingForToken"
        :message="$t('message.we_are_preparing_your_account')"
      >
        <h1 class="register__title">{{ $t("c.login.title") }}</h1>
        <div class="register__subtitle mb-0">
          {{ $t("c.login.subtitle_line_01") }}
        </div>
        <div class="register__subtitle mt-2">
          {{ $t("c.login.subtitle_line_02") }}
        </div>
        <FormControl required class="mb-4" :label="$t('label.email')">
          <Input
            id="email"
            v-model="record.email"
            type="email"
            autofocus
            :placeholder="$t('label.enter_email')"
          />
        </FormControl>
        <Anchor
          tabindex="-1"
          class="login-form__forgot"
          :link="{ name: 'auth.forgot-password' }"
        >
          {{ $t("c.login.forgot_password") }}
        </Anchor>
        <FormControl required class="mb-4" :label="$t('label.password')">
          <InputPassword
            id="password"
            v-model="record.password"
            type="password"
            :placeholder="$t('label.password')"
          />
        </FormControl>
        <Button type="submit" class="btn-block" :is-loading="loading">
          {{ $t("label.login") }}
        </Button>
      </LoadingZone>
    </form>
    <template slot="info">
      <OrgTipsSlider />
    </template>
  </register-layout>
</template>

<script>
import LoadingZone from "@/components/helpers/LoadingZone.vue";
import { OrgTipsSlider } from "@/v2/new-design-system";
import { mapActions } from "vuex";
import { isNil } from "ramda";
import { jwtToken, bootstrap } from "@/utils/helpers/functions";
import {
  FormControl,
  Input,
  InputPassword,
  Button,
  Anchor,
  Toast,
} from "@/design-system";

export default {
  name: "Login",
  components: {
    LoadingZone,
    FormControl,
    Input,
    InputPassword,
    Button,
    Anchor,
    OrgTipsSlider,
  },
  props: {
    hash: {
      type: String,
      default: null,
    },
  },
  data: () => ({
    record: {
      email: "",
      password: "",
    },
    hasToken: false,
    loading: false,
    loadingForToken: false,
  }),
  beforeMount() {
    if (jwtToken.get()) {
      this.$router.push({
        name: "app.index",
      });
      this.hasToken = true;
    }
  },
  mounted() {
    if (this.hash) {
      this.performMagicLogin();
    }
    if (!isNil(this.$route.query.email)) {
      this.record.email = this.$route.query.email;
    }
  },
  methods: {
    ...mapActions({
      fetch: "auth/fetch",
      login: "auth/login",
    }),
    async performMagicLogin() {
      try {
        this.loadingForToken = true;
        await this.attemptLoginWith({ hash: this.hash });
      } catch (e) {
        new Toast().create({
          type: "error",
          title: this.$t("label.oops"),
          text: this.$t("message.auto_sigin_failure"),
        });
        this.loadingForToken = false;
      }
    },
    async performLogin() {
      try {
        this.loading = true;
        await this.attemptLoginWith(this.record);
      } catch (e) {
        new Toast().create({
          type: "error",
          title: this.$t("label.oops"),
          text: this.$t("label.invalid_credentials"),
        });
        this.loading = false;
      }
    },
    async attemptLoginWith(credentials) {
      const token = await this.login(credentials);
      await bootstrap(token, this.$store.dispatch, {
        gates: this.$gates,
        intercom: this.$intercom,
        hotjar: this.$hotjar,
        analytics: this.$analytics,
        urlParams: {},
      });
      this.redirectUser();
    },
    redirectUser() {
      const goTo = !isNil(this.$route.query.goTo)
        ? this.$route.query.goTo
        : { name: "app.index" };
      this.$router.push(goTo);
    },
  },
};
</script>

<style lang="scss" scoped>
.login-form {
  &__subtitle {
    max-width: 270px;
    font-weight: 400;
    color: #071c4d;
    font-size: 14px;
    line-height: 1.5;
    margin: 20px 0 40px;
  }

  &__forgot {
    color: #1258ff;
    float: right;
    font-size: 12px;
    line-height: 1.5;
  }
}
</style>
